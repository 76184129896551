const happeningsArray = ['Happenings'];

const storyArray = ['Designer-Stories', 'Designer-Stories-Overview'];

const inMotionArray = [
  'Roadshow-Design-Through-Heritage',
  'Roadshow-Art-and-Living',
  'HKCM-Anhui',
  'HKCM-Tokyo',
];

const showcaseArray = [
  'Showcase',
  'Council-of-Fashion-Designers-Tokyo',
  'Japan-Pavilion',
  'BioMelbourne-Network-Pavilion',
  'ItalianDesignPavilion',
  'Melbourne-Pavilion',
  'Hangzhou-Pavilion',
  'Hong-Kong-Design-Centre',
  'Hong-Kong-Design-Institute',
];

const storyDetailsArray = [
  'Kennif-Studio',
  'Chilai-Howard',
  'Mui-Kinoshita',
  'YanYan',
  'Oddity-Studio',
  'Sampson-Wong',
  'BeCandle',
  'Wholly-Wholly',
  'Index-Architecture',
  'HIR-Studio',
  'Solos-Technology',
  'EONIQ',
  'vfxNova',
  'Creative-Victoria',
  'Mirum',
  'HKDI',
  'WARE',
  'CoDESIGN',
  'nocompany',
  'IOIO-Creative',
  'Toby-Ng-Design',
  'Never-Never',
  'Teaspoon',
  'Alchemist-Creations',
  'Wolter-Yu',
  'One-Bite-Design-Studio',
  'ARTA-Architects',
  'One-Object-Design',
  'Pong-Product',
  'Motoki-Ishikawa',
  'JBMA',
  'C4Cat',
  'Minidragon',
  'Rock-Water',
  'Hintegro',
  'XCEPT',
  'Sino-Inno-Lab',
  'HK-Masking-Tapes',
  'Count-to-Ten',
  'LAAB',
  'pillandpillow',
  'One-Plus',
  'Oft-Interiors',
  'Plant216',
  'Growgreen',
  'XPAND-KK',
  'Bamboo-Intl',
  'Effect-by-EE',
  'MAD-Studio',
  'half-and-half-Studio',
  'Nude-Design',
  'Studio-Gooris',
  'Musician-Designer',
  'Desixnlab',
  'O-and-O-Studio',
  'Stylus-Studio',
  'Karen-Aruba-Art',
  'Ditto-Ditto',
  'Shabibi-Sheep-Workshop',
  'LLAB-Design',
  'Noiseless-Design',
  'ReALLY-Design',
];

export { storyArray, happeningsArray, inMotionArray, showcaseArray, storyDetailsArray };
