import React, { useEffect } from 'react';
import { useEffectOnce } from 'react-use';
import { Route, Router, Switch, Redirect } from 'react-router-dom'; // Switch, Redirect
import { createBrowserHistory } from 'history';
import loadable from '@loadable/component';
import { connect } from 'react-redux';
import Main from '../Layouts/Main';
import { localeList } from '../../utils/Helper';
import store from '../../redux/store';
import { getSiteConfig } from '../../redux/modules/siteConfig/action';

import c from '../../config';

// Default Style
import '../../assets/scss/theme.scss';

// Bootstrap Icons
import 'bootstrap-icons/font/bootstrap-icons.css';

// AOS
import AOS from 'aos';
import 'aos/dist/aos.css';

//RedirectionList
import {
  storyArray,
  happeningsArray,
  inMotionArray,
  showcaseArray,
  storyDetailsArray,
} from '../../constants/RedirectionList';

// cookie
const CookieConsent = loadable(() => import('../../components/CookieConsent'));

// SSO
const SSOStatus = loadable(() => import('../../components/SSOStatus'));

// Routes
const HomePage = loadable(() => import('../../pages/HomePage'));
const IndexPage = loadable(() => import('../../pages/IndexPage'));
const StaticPage = loadable(() => import('../../pages/StaticPage'));
const SpeakersPage = loadable(() => import('../../pages/SpeakersPage'));
const ProgrammePage = loadable(() => import('../../pages/ProgrammePage'));
const PhotoGalleryPage = loadable(() => import('../../pages/PhotoGalleryPage'));
const VideoGalleryPage = loadable(() => import('../../pages/VideoGalleryPage'));
const LinksPage = loadable(() => import('../../pages/LinksPage'));
const PressReleasePage = loadable(() => import('../../pages/PressReleasePage'));
const PromotionPage = loadable(() => import('../../pages/PromotionPage'));
const NoMatch = loadable(() => import('../../pages/NoMatch'));
const ComingSoonPage = loadable(() => import('../../pages/ComingSoonPage'));
const DirectoryPage = loadable(() => import('../../pages/DirectoryPage'));
const ExhibitorPage = loadable(() => import('../../pages/ExhibitorPage'));

const DesignInspireInMotionMainPage = loadable(() =>
  import('../../pages/DesignInspireInMotionMainPage'),
);
const DesignInspireInMotionEventPage = loadable(() =>
  import('../../pages/DesignInspireInMotionEventPage'),
);
const HappeningsPage = loadable(() => import('../../pages/HappeningsPage'));
const StoryMainPage = loadable(() => import('../../pages/StoryMainPage'));
const StoryCategoryPage = loadable(() => import('../../pages/StoryCategoryPage'));
const StoryDetailPage = loadable(() => import('../../pages/StoryDetailPage'));
// Config Top Application, include languages
const TopApp = loadable(() => import('../../widgets/TopApp'));

const history = createBrowserHistory();

const ArrayToString = (array) => {
  return array.join('|');
};

const App = ({ ...props }) => {
  const { siteConfig } = props;
  const { data = {} } = siteConfig || {};
  const { availableLanguages, defaultLanguage, enableSSO, under_construction } = data;
  const isUnderConstruction = under_construction === 'true' || under_construction === true;

  useEffectOnce(() => {
    store.dispatch(getSiteConfig());
  });

  useEffect(() => {
    AOS.init({
      delay: 10,
      duration: 1000,
      easing: 'ease-in-sine',
      useClassNames: true,
      initClassName: false,
      animatedClassName: 'animate__animated',
      //				, disable: function() {    var maxWidth = 800;   return window.innerWidth < maxWidth;   }
    });
    AOS.refresh();
  }, []);

  //redirection
  const redirects = availableLanguages && [
    {
      from: `${c.routePathTo}/:locale(${localeList(
        availableLanguages,
      )})/s/:happeningsItem(${ArrayToString(happeningsArray)})`,
      to: `${c.routePathTo}/:locale(${localeList(availableLanguages)})/happenings`,
    },
    {
      from: `${c.routePathTo}/:locale(${localeList(
        availableLanguages,
      )})/s/:storyItem(${ArrayToString(storyArray)})`,
      to: `${c.routePathTo}/:locale(${localeList(availableLanguages)})/story`,
    },
    {
      from: `${c.routePathTo}/:locale(${localeList(
        availableLanguages,
      )})/s/:inMotionItem(${ArrayToString(inMotionArray)})`,
      to: `${c.routePathTo}/:locale(${localeList(
        availableLanguages,
      )})/inMotion/:inMotionItem`,
    },
    {
      from: `${c.routePathTo}/:locale(${localeList(availableLanguages)})/s/HKCF-Art-and-City`,
      to: `${c.routePathTo}/:locale(${localeList(availableLanguages)})/inMotion/Roadshow-Art-and-City`,
    },
    {
      from: `${c.routePathTo}/:locale(${localeList(
        availableLanguages,
      )})/s/:showcaseItem(${ArrayToString(showcaseArray)})`,
      to: `${c.routePathTo}/:locale(${localeList(
        availableLanguages,
      )})/directory/:showcaseItem`,
    },
    {
      from: `${c.routePathTo}/:locale(${localeList(availableLanguages)})/s/Galleries`,
      to: `${c.routePathTo}/:locale(${localeList(availableLanguages)})/directory/Showcase`,
    },
    {
      from: `${c.routePathTo}/:locale(${localeList(availableLanguages)})/s/Studio`,
      to: `${c.routePathTo}/:locale(${localeList(availableLanguages)})/directory/Showcase/studio`,
    },
    {
      from: `${c.routePathTo}/:locale(${localeList(availableLanguages)})/s/Designer-Product`,
      to: `${c.routePathTo}/:locale(${localeList(availableLanguages)})/directory/Showcase/designer-product`,
    },
    {
      from: `${c.routePathTo}/:locale(${localeList(availableLanguages)})/s/Art-Tech`,
      to: `${c.routePathTo}/:locale(${localeList(availableLanguages)})/directory/Showcase/art-tech`,
    },
    {
      from: `${c.routePathTo}/:locale(${localeList(availableLanguages)})/s/Award`,
      to: `${c.routePathTo}/:locale(${localeList(availableLanguages)})/directory/Showcase/award`,
    },
    {
      from: `${c.routePathTo}/:locale(${localeList(availableLanguages)})/s/Education`,
      to: `${c.routePathTo}/:locale(${localeList(availableLanguages)})/directory/Showcase/education`,
    },
    {
      from: `${c.routePathTo}/:locale(${localeList(availableLanguages)})/s/Innovative-Solution`,
      to: `${c.routePathTo}/:locale(${localeList(availableLanguages)})/directory/Showcase/innovative-solution`,
    },
    {
      from: `${c.routePathTo}/:locale(${localeList(availableLanguages)})/s/Institution`,
      to: `${c.routePathTo}/:locale(${localeList(availableLanguages)})/directory/Showcase/institution`,
    },
    {
      from: `${c.routePathTo}/:locale(${localeList(
        availableLanguages,
      )})/s/:storyDetailsItem(${ArrayToString(storyDetailsArray)})`,
      to: `${c.routePathTo}/:locale(${localeList(
        availableLanguages,
      )})/story/details/:storyDetailsItem`,
    },
  ];

  const redirectRoutes =
    availableLanguages &&
    redirects.map((redirect, index) => (
      <Redirect exact key={index} from={redirect.from} to={redirect.to} />
    ));

  return (
    <>
      <CookieConsent />
      {enableSSO && <SSOStatus />}
      {availableLanguages && (
        <Router history={history} basename={c.routePathTo}>
          <Route
            path={`${c.routePathTo}/:locale(${localeList(availableLanguages)})?`}
            component={TopApp}
          />
          <Main
            availableLanguages={localeList(availableLanguages)}
            isUnderConstruction={isUnderConstruction}
          >
            <Switch>
              {isUnderConstruction && <Route component={ComingSoonPage} />}
              <Route
                exact
                path={`${c.routePathTo}/:locale(${localeList(availableLanguages)})?`}
                component={HomePage}
              />
              <Route
                exact
                path={`${c.routePathTo}/:locale(${localeList(
                  availableLanguages,
                )})/photo/:pageName/:id?`}
                component={PhotoGalleryPage}
              />
              <Route
                exact
                path={`${c.routePathTo}/:locale(${localeList(
                  availableLanguages,
                )})/video/:pageName/:id?`}
                component={VideoGalleryPage}
              />
              <Route
                exact
                path={`${c.routePathTo}/:locale(${localeList(
                  availableLanguages,
                )})/speaker/:pageName/:id?`}
                component={SpeakersPage}
              />
              <Route
                exact
                path={`${c.routePathTo}/:locale(${localeList(
                  availableLanguages,
                )})/programme/:pageName`}
                component={ProgrammePage}
              />
              <Route
                exact
                path={`${c.routePathTo}/:locale(${localeList(
                  availableLanguages,
                )})/directory/exh/:targetID`}
                component={ExhibitorPage}
              />
              <Route
                exact
                path={`${c.routePathTo}/:locale(${localeList(
                  availableLanguages,
                )})/directory/:pageName/:category?/:subCategory?`}
                component={DirectoryPage}
              />
              <Route
                exact
                path={`${c.routePathTo}/:locale(${localeList(availableLanguages)})/inMotion`}
                component={DesignInspireInMotionMainPage}
              />
              <Route
                exact
                path={`${c.routePathTo}/:locale(${localeList(
                  availableLanguages,
                )})/inMotion/:pageName`}
                component={DesignInspireInMotionEventPage}
              />
              <Route
                exact
                path={`${c.routePathTo}/:locale(${localeList(
                  availableLanguages,
                )})/happenings/:type?`}
                component={HappeningsPage}
              />

              <Route
                exact
                path={`${c.routePathTo}/:locale(${localeList(
                  availableLanguages,
                )})/story/:category?`}
                component={StoryMainPage}
              />
              <Route
                exact
                path={`${c.routePathTo}/:locale(${localeList(
                  availableLanguages,
                )})/story/details/:pageName`}
                component={StoryDetailPage}
              />
              {redirectRoutes}
              <Route
                exact
                path={`${c.routePathTo}/:locale(${localeList(availableLanguages)})/s/:pageName`}
                component={StaticPage}
              />
              <Route
                exact
                path={`${c.routePathTo}/:locale(${localeList(
                  availableLanguages,
                )})/i/:pageName/:curPage?`}
                component={IndexPage}
              />
              <Route
                exact
                path={`${c.routePathTo}/:locale(${localeList(availableLanguages)})/l/:pageName`}
                component={LinksPage}
              />
              <Route
                exact
                path={`${c.routePathTo}/:locale(${localeList(
                  availableLanguages,
                )})/press-release/:pageName`}
                component={PressReleasePage}
              />
              <Route
                exact
                path={`${c.routePathTo}/:locale(${localeList(
                  availableLanguages,
                )})/promotion/:pageName`}
                component={PromotionPage}
              />
              <Route
                exact
                path={`${c.routePathTo}/:path`}
                render={(props) => (
                  <Redirect to={`${c.routePathTo}/${defaultLanguage}/${props.match.params.path}`} />
                )}
              />
              <Route component={NoMatch} />
            </Switch>
          </Main>
        </Router>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  siteConfig: state.siteConfig,
});

const connectStore = connect(mapStateToProps, null, null, {
  pure: true,
})(App);

export { App as AppComponent }; // <- For component Unit Test
export default connectStore;
